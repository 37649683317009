import React from 'react'
import {render} from 'react-dom'
import {HashRouter as Router, Route, Switch, Redirect, Link} from 'react-router-dom'
import Box from 'ui-box'
import {observer} from 'mobx-react'

// import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'
import './styles.scss'

import OverviewPage from './pages/overview'
import ReportPage from './pages/report'
import CalcsPage from './pages/calcs'
import globalState from './globalState'
import {Login, Logout} from './auth'
import FullscreenLoader from './FullscreenLoader'
import bootstrapApp from './bootstrapApp'

const ASSETS_DIR = './assets/'
export const Header = () => {
	const {user} = globalState
	return (
		<Box display="flex">
			<Box
				backgroundImage={`url(${require(ASSETS_DIR + 'barrasup-row.jpg')})`}
				height="58px"
				width="5px"
			/>
			<Box
				backgroundImage={`url(${require(ASSETS_DIR + 'barrasup-title.jpg')})`}
				height="58px"
				width="412px"
			/>
			<Box
				backgroundImage={`url(${require(ASSETS_DIR + 'barrasup-row.jpg')})`}
				height="58px"
				width="20px"
				flex={1}
			/>
			<Box
				backgroundImage={`url(${require(ASSETS_DIR + 'barrasup-rainbow.jpg')})`}
				height="58px"
				width="289px"
			/>
			{user && <Link
				style={{
					position: 'absolute',
					right: 0,
					top: 0,
					padding: '6px 10px',
					opacity: 0.7,
				}}
				to="/logout"
			>
				Esci
			</Link>}
		</Box>
	)
}

const PrivateApp = () => (
	<Box flex={1} overflowY="hidden">
		<Switch>
			<Route exact path="/logout" component={Logout} />
			<Route exact path="/" component={OverviewPage} />
			<Route
				exact
				path="/:page(inserimento|report|grafici)/:codistat/:anno"
				component={ReportPage}
			/>
			<Route exact path="/calcs/:codistat/:anno" component={CalcsPage} />
			<Redirect to="/" />
		</Switch>
	</Box>
)

const App = observer(() => {
	const {isBootstrapping, user} = globalState
	if (isBootstrapping) return <FullscreenLoader />
	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Header />
			{user ? (
				<PrivateApp />
			) : (
				<Switch>
					<Route exact path="/" component={Login} />
					<Redirect to="/" />
				</Switch>
			)}
		</Box>
	)
})

const rootElement = document.getElementById('root')
render(
	<Router>
		<App />
	</Router>,
	rootElement
)

bootstrapApp()
// serviceWorker.unregister()
