import React from 'react'
import {Select, Form} from 'antd'
import styled from 'styled-components'

import {comuni, anni} from '../data'

export const ComuniSelect = ({value = undefined, onChange = undefined} = {}) => (
	<Form.Item label="Comune">
		<Select
			// style={{ width: 150 }}
			value={value}
			onChange={onChange}
		>
			{comuni.map(x => (
				<Select.Option key={x}>{x}</Select.Option>
			))}
		</Select>
	</Form.Item>
)

export const Container = styled.div`
	padding: 10px;
`

export const VGroup = styled.div`
	> * {
		display: block;
		margin-bottom: 10px !important;
	}
	> *:last-child {
		margin-bottom: 0 !important;
	}
`

export const HGroup = styled.div`
	display: flex;
	> * {
		display: block;
		margin-right: 10px !important;
	}
	> *:last-child {
		margin-right: 0 !important;
	}
`
