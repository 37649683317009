import React from 'react'
import axios from 'axios'
import globalState from './globalState'
import styled from 'styled-components'
import {VGroup} from './components/ui'
import {Input, Button, Spin, Alert} from 'antd'

const AUTH_API_URL = '/api/auth'

const formatError = err => {
	return String(err.response.status)[0] === '4' ? err.response.data || err.message : 'Server Error'
}

export const login = async (username, password) => {
	try {
		const {success, error} = (await axios.post(AUTH_API_URL + '/login', {username, password})).data
		if (error) throw error
		if (success) {
			await updateAuthStatus()
			return {success: true}
		} else {
			return {success: false}
		}
	} catch (err) {
		console.error(err)
		return {success: false, error: formatError(err)}
	}
}

export const logout = async () => {
	try {
		const {success, error} = (await axios.post(AUTH_API_URL + '/logout')).data
		if (error) throw error
		if (success) {
			globalState.user = null
			return {success: true}
		} else {
			return {success: false}
		}
	} catch (err) {
		console.error(err)
		return {success: false, error: formatError(err)}
	}
}

export const updateAuthStatus = async () => {
	try {
		const user = (await axios.get(AUTH_API_URL + '/me')).data || null
		globalState.user = user
	} catch (err) {
		globalState.user = null
	}
}

const LoginScreen = styled(({className, onSubmit, error, loading}) => (
	<div className={className}>
		<Spin spinning={loading}>
			<form
				style={{width: 400}}
				onSubmit={e => {
					e.preventDefault()
					const form = e.target as any
					onSubmit({
						username: form.username.value,
						password: form.password.value,
					})
				}}
			>
				<VGroup>
					<label>
						Username
						<Input name="username" />
					</label>
					<label>
						Password
						<Input name="password" type="password" />
					</label>

					{error && <Alert message={error} type="error" />}

					<Button htmlType="submit" type="primary">
						Accedi
					</Button>
				</VGroup>
			</form>
		</Spin>
	</div>
))`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`

export class Login extends React.Component {
	state = {
		error: null,
		loading: false,
	}

	onSubmit = async ({username, password}) => {
		this.setState({loading: true})
		const {success, error} = await login(username, password)
		if (error) this.setState({error, loading: false})
		else this.setState({loading: false})
		return success
	}

	render() {
		return (
			<LoginScreen onSubmit={this.onSubmit} error={this.state.error} loading={this.state.loading} />
		)
	}
}

export const Logout = () => {
	logout()
	return null
}
