import React from 'react'
import {Spin, Alert, Tabs, Icon, Select} from 'antd'
import {Link, withRouter, useHistory, useRouteMatch} from 'react-router-dom'
import _ from 'lodash'

import {useReportData, extractDataForTextualReport, useOverviewData} from '../data'
import ReportChartRenderer from '../components/report-charts'
import TextualReportRenderer from '../components/textual-report'
import DataFormRenderer from './operations'
import {MyFormItem} from '../components/forms'
import Box from 'ui-box'
import {dump} from '../utils'
import {HGroup} from '../components/ui'

const topFormItemsProps = {
	labelStyle: {width: 'auto'},
}

const useComuneAnnoSelectors = ({codistat, anno}) => {
	const history = useHistory()
	const {params} = useRouteMatch()
	const {page} = params
	const {loading, data, error} = useOverviewData()
	const ComuneSelector = () => {
		return loading ? (
			<Spin />
		) : error ? (
			<Alert type="error" message={error} />
		) : (
			<MyFormItem label="Comune" {...topFormItemsProps}>
				<Select
					style={{width: 400}}
					showSearch
					value={codistat}
					onChange={codistat => history.push(`/${page}/${codistat}/${anno}`)}
					filterOption={(input, option) => {
						const text = _.get(option, 'props.children', '') as string
						const re = new RegExp(input, 'i')
						return re.test(text)
					}}
				>
					{data.comuni
						.sort((a, b) =>
							a.comune > b.comune
								? 1
								: a.comune < b.comune
								? -1
								: a.codistat > b.codistat
								? 1
								: -1
						)
						.map(({comune, codistat}) => (
							<Select.Option key={codistat} value={codistat}>
								{comune}{' '}
								<small style={{opacity: 0.3, fontStyle: 'italic'}}>
									{codistat}
								</small>
							</Select.Option>
						))}
				</Select>
			</MyFormItem>
		)
	}

	const AnnoSelector = () => {
		return loading ? (
			<Spin />
		) : error ? (
			<Alert type="error" message={error} />
		) : (
			// dump({anni: data.anni, anno})
			<MyFormItem label="Anno" {...topFormItemsProps}>
				<Select
					value={+anno}
					style={{width: 100}}
					onChange={anno => history.push(`/${page}/${codistat}/${anno}`)}
				>
					{data.anni.map(anno => (
						<Select.Option key={anno} value={anno}>
							{anno}
						</Select.Option>
					))}
				</Select>
			</MyFormItem>
		)
	}
	return {ComuneSelector, AnnoSelector}
}

const MissingDataRenderer = withRouter(({history, match: {params: {page, anno, codistat}}}) => (
	<Box paddingLeft={20} paddingRight={20}>
		Non è ancora stato eseguito uno snapshot per questo comune/anno.{' '}
		<Link to={`/inserimento/${codistat}/${anno}`}>Procedi all'inserimento.</Link>
	</Box>
))

const MyTabPane = ({children, active}) => (
	<div
		style={{
			width: '100%',
			flexShrink: 0,
			overflowY: 'auto',
		}}
	>
		{active && children}
	</div>
)

const ReportPage = withRouter(({match, history}) => {
	const {
		params: {page, anno, codistat},
	} = match
	const {loading, data, error} = useReportData({anno, codistat})

	const {ComuneSelector, AnnoSelector} = useComuneAnnoSelectors({codistat, anno})
	return (
		<Box height="100%" display="flex" flexDirection="column">
			<Box display="flex" paddingTop={10} height={52}>
				<MyFormItem {...topFormItemsProps} wrapperStyle={{width: 270 + 10}}>
					<Link to="/">
						<Icon type="left" /> Torna alla panoramica
					</Link>
				</MyFormItem>
				<HGroup>
					<ComuneSelector />
					<AnnoSelector />
				</HGroup>
			</Box>
			{loading ? (
				<Spin />
			) : error ? (
				<Alert type="error" message={Error} />
			) : (
				<>
					<Tabs
						style={{height: 44}}
						activeKey={page}
						onChange={key => history.push(`/${key}/${codistat}/${anno}`)}
					>
						<Tabs.TabPane key="inserimento" tab="Inserimento"></Tabs.TabPane>
						<Tabs.TabPane
							key="report"
							tab="Report"
							disabled={!data.risultati}
						></Tabs.TabPane>
						<Tabs.TabPane
							key="grafici"
							tab="Grafici"
							disabled={!data.risultati}
						></Tabs.TabPane>
					</Tabs>
					<div
						style={{
							flex: 1,
							overflow: 'hidden',
						}}
					>
						<div
							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								marginLeft: `-${
									['inserimento', 'report', 'grafici'].indexOf(page) * 100
								}%`,
								transition: 'margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
								willChange: 'margin-left',
							}}
						>
							<MyTabPane active={page === 'inserimento'}>
								<DataFormRenderer />
							</MyTabPane>
							<MyTabPane active={page === 'report'}>
								{data.risultati ? (
									<TextualReportRenderer
										data={extractDataForTextualReport({data, anno})}
									/>
								) : (
									<MissingDataRenderer />
								)}
							</MyTabPane>
							<MyTabPane active={page === 'grafici'}>
								{data.risultati ? (
									<ReportChartRenderer data={data} />
								) : (
									<MissingDataRenderer />
								)}
							</MyTabPane>
						</div>
					</div>
				</>
			)}
		</Box>
	)
})

export default ReportPage
