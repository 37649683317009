import React, {
	FunctionComponent,
	useRef,
	useEffect,
	useState,
	useImperativeHandle,
	forwardRef,
} from 'react'
import * as d3 from 'd3'
import d3legend from 'd3-svg-legend'
import _ from 'lodash'
import styled from 'styled-components'

const margin = {
	left: 38, // 70 if not formatting y axis
	right: 10,
	top: 40,
	bottom: 23,
}
const width = 400
const height = 270

d3.formatDefaultLocale(require('d3-format/locale/it-IT.json'))

export const Legend = styled(({series, className}) => {
	const legendRef = useRef()
	const legend = d3legend
		.legendColor()
		.shape(
			'path',
			d3
				.symbol()
				.type(d3.symbolCircle)
				.size(50)()
		)
		// .shapePadding(10)
		.scale(
			d3
				.scaleOrdinal()
				.domain(series.map(d => d.label))
				.range(series.map(d => d.color))
		)
	d3.select(legendRef.current).call(legend)
	return (
		<g
			ref={legendRef}
			className={className}
			// transform={`translate(${width - margin.right - 200},30)`}
		/>
	)
})`
	width: 100%;
	height: 100%;
	text.label {
		font-family: sans-serif;
		text-rendering: optimizeLegibility;
		font-weight: 100;
		font-size: 0.8rem;
	}
`

export const BarChart: FunctionComponent<any> = forwardRef(
	(
		{title, data, series, benchmarks = null}: {series: Array<{key: string; color: string}>} & any,
		ref
	) => {
		const yAxisRef = useRef()
		const xAxisRef = useRef()
    const svgRef = useRef()

		useImperativeHandle(ref, () => ({
			getSVG: () => svgRef.current,
		}))

		const [bars, setBars] = useState([])
		const [benchmarkLines, setBenchmarkLines] = useState([])
		useEffect(() => {
			benchmarks = benchmarks || []
			console.log('effect')
			const xScale = d3
				.scaleBand()
				.rangeRound([margin.left, width - margin.right])
				.padding(0.2)
				.round(true)
			const yScale = d3
				.scaleLinear()
				.range([height - margin.bottom, margin.top])
				.nice()
			const xAxis = d3.axisBottom(xScale)
			const yAxis = d3.axisLeft(yScale).tickFormat(d3.format('~s')) // alternative format: '.2s'
			const xExtent = d3.extent(Object.keys(data))
			const xDomain = _.range(parseInt(xExtent[0]), parseInt(xExtent[1]) + 1).map(d => String(d))
			const yDomain = d3.extent([
				0,
				// ..._.flatten(Object.values(data).map(d => Object.values(d)))
				..._.flatten(Object.values(data).map(d => series.map(({key}) => _.get(d, key)))),
				...benchmarks.map(d => d.value),
			])
			xScale.domain(xDomain)
			yScale.domain(yDomain)
			d3.select(xAxisRef.current).call(xAxis)
			d3.select(yAxisRef.current).call(yAxis)
			setBars(
				xDomain.map(year => {
					return series.map(({key, color}, i) => {
						const y1 = yScale(_.get(data, `${year}.${key}`, 0))
						const y2 = yScale(0)
						const height = y2 - y1
						const x = xScale(year) + (i * xScale.bandwidth()) / series.length
						return {
							x,
							y1,
							y2,
							height,
							width: xScale.bandwidth() / series.length,
							color,
						}
					})
				})
			)
			setBenchmarkLines(benchmarks.map(({value}) => ({value, y: yScale(value)})))
		}, [data, benchmarks])
		return (
			<div
				style={{
					position: 'relative',
					width,
					height,
					display: 'inline-block',
					backgroundColor: '#f9f9f9',
				}}
			>
				<svg width={width} height={height} ref={svgRef}>
					<g>
						<g ref={yAxisRef} transform={`translate(${margin.left},0)`} />
						<g ref={xAxisRef} transform={`translate(0,${height - margin.bottom})`} />
					</g>
					{bars.map((yearData, i) =>
						yearData.map((d, j) => (
							<rect
								key={`${i}.${j}`}
								fill={d.color}
								x={d.x}
								y={d.y1}
								height={d.height}
								width={d.width}
							/>
						))
					)}
					{benchmarkLines.map(({y}, i) => (
						<g transform={`translate(0,${y})`} key={`benchmark-${i}`}>
							<line
								x1={margin.left}
								x2={width}
								y1={0}
								y2={0}
								style={{
									stroke: 'rgb(255,0,0)',
									strokeWidth: 1.2,
									// strokeDasharray: "8,4,2,2,2,4"
								}}
							/>
						</g>
					))}

					{/* {title && (
          <text
            width={width}
            textAnchor="middle"
            x={width / 2}
            y={margin.top / 2}
            fontWeight={600}
            textRendering="geometricPrecision"
            fill="#333"
          >
            {title}
          </text>
        )} */}
					{/* <Legend series={series} /> */}
				</svg>
				{/* BENCHS {JSON.stringify(benchmarkLines)} */}
				{title && (
					<p
						style={{
							position: 'absolute',
							top: 0,
							width: '100%',
							textAlign: 'center',
							color: '#333',
							fontWeight: 600,
							paddingLeft: 20,
							paddingRight: 20,
						}}
					>
						{title}
					</p>
				)}
			</div>
		)
	}
)
