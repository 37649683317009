import React from 'react'
import {withRouter, matchPath} from 'react-router'
import {Spin} from 'antd'
import _ from 'lodash'
import {useData} from '../data'
import {dump} from '../utils'

const Explorer = withRouter(({location, match}) => {
	const {anno, codistat} = match.params
	const {data, loading, error} = useData({anno, codistat})
	return (
		<div>
			<Spin spinning={loading}>{dump(data)}</Spin>
		</div>
	)
})

export default Explorer
