import React from 'react'
import {Link} from 'react-router-dom'
import {Spin, Alert} from 'antd'
import styled from 'styled-components'

import {useOverviewData} from '../data'

const Table = styled(({className, data: {anni, comuni}}) => (
	<div className={className}>
		<div className="tbl-header">
			<div className="tbl-cell">Comune</div>
			{anni.map(anno => (
				<div key={anno} className="tbl-cell">
					{anno}
				</div>
			))}
		</div>
		<div className="tbl-body">
			{comuni
				.sort(({comune: a}, {comune: b}) => {
					var res
					if (a === b) res = 0
					if (a === null || a < b) res = -1
					if (b === null || a > b) res = 1
					if (res === undefined) {
						console.error('Unhandled case', a, b)
						res = 0
					}
					return res
				})
				.map(({comune, codistat, metriche, calcoli}) => {
					return (
						<div className="tbl-row" key={codistat}>
							<div className="tbl-cell">
								{comune} <small className="tbl-cell-codistat">{codistat}</small>
							</div>
							{anni.map(anno => (
								<div key={`${codistat}.${anno}`} className="tbl-cell">
									{
										<Link to={`/inserimento/${codistat}/${anno}`}>
											{calcoli[anno] ? '✅' : metriche[anno] ? '⚠️' : '❌'
											// '🚫'
											}
										</Link>
									}
								</div>
							))}
						</div>
					)
				})}
		</div>
	</div>
))`
	height: 100%;
	display: flex;
	flex-direction: column;

	.tbl-header,
	.tbl-row {
		display: flex;
		border-bottom: 1px solid #e8e8e8;
	}
	.tbl-cell:first-child {
		width: 300px;
	}
	.tbl-cell:not(:first-child) {
		flex: 1;
		text-align: center;
	}
	.tbl-body {
		flex: 1;
		overflow-y: auto;
	}

	.tbl-cell-codistat {
		opacity: 0.3;
		font-style: italic;
	}

	.tbl-cell {
		padding: 10px;
	}

	.tbl-header {
		// background-color: #fafafa;
		font-weight: bold;
		box-shadow: 0 1px 15px rgba(0, 0, 0, 0.17);
	}
`

export default () => {
	const {loading, data, error} = useOverviewData()
	return loading ? (
		<Spin
			style={{
				height: '100%',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		/>
	) : error ? (
		<Alert type="error" message={error} />
	) : (
		<Table data={data} />
	)
}
