import React from 'react'

export function dump(data) {
	return <pre>{JSON.stringify(data, null, 2)}</pre>
}

export async function svg2png(svgNode, width, height) {
	return new Promise((res, rej) => {
		try {
			var canvas = document.createElement('canvas')
			canvas.width = width
			canvas.height = height
			var ctx = canvas.getContext('2d')
			var data = new XMLSerializer().serializeToString(svgNode)

			var img = new Image()
			var svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'})
			var url = URL.createObjectURL(svgBlob)

			img.onload = function() {
				try {
					ctx.drawImage(img, 0, 0, width, height)
					URL.revokeObjectURL(url)
					var imgURI = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
					res(imgURI)
				} catch (err) {
					rej(err)
				}
			}

			img.src = url
		} catch (err) {
			rej(err)
		}
	})
}
