import React, {FunctionComponent} from 'react'
import _ from 'lodash'

export const MyFormItem: FunctionComponent<{
	children?
	label?
	required?
	wrapperStyle?: any
	help?: string
	error?: string
	labelStyle?: any
}> = ({
	children,
	label = null,
	required = false,
	wrapperStyle = null,
	labelStyle = null,
	help = null,
	error = null,
}) => (
	<div
		style={{
			marginBottom: 10,
			...wrapperStyle,
		}}
	>
		<div
			style={{
				display: 'flex',

				alignItems: 'center',
			}}
		>
			<div style={{width: 270, textAlign: 'right', marginRight: 10, ...labelStyle}}>
				{required && <span style={{color: 'red'}}>* </span>}
				{label || ' '}
			</div>
			<div style={{flex: 1}}>{children}</div>
		</div>
		<div style={{marginLeft: 270 + 10}}>
			{help && <span style={{opacity: 0.7, fontStyle: 'italic'}}>{help}</span>}{' '}
			{error && <span style={{color: 'red'}}>{error}</span>}
		</div>
	</div>
)
