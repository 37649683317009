import React from 'react'
import _ from 'lodash'
import {Container} from './ui'

const TextualReportRenderer = ({data: {_vettori_energetici, ...data}}) => {
	const vettori_energetici_lookup = _.zipObject(
		_.map(_vettori_energetici, 'vettore'),
		_vettori_energetici
	)
	const commonTableProps = {
		cellPadding: 5,
		// width: '100%',
	}
	const TH = ({indent = 1, children}) => <th style={{paddingLeft: 20 * indent}}>{children}</th>
	return (
		<Container>
			<table {...commonTableProps}>
				<tr>
					<th>Consumo en primaria tot:</th>
					<td>{_.get(data, 'consumo_en_primaria_tot') + ' kWh'}</td>
				</tr>
				<tr>
					<th>Spesa tot:</th>
					<td>{_.get(data, 'spesa_tot') + ' €'}</td>
				</tr>
				<tr>
					<th>Consumo per vettore:</th>
				</tr>
				{_.keys(_.get(data, 'consumo_per_vettore', {})).map(k => (
					<tr key={`consumo_per_vettore.${k}`}>
						<TH>{k.replace(/_/g, ' ')}:</TH>
						<td>
							{_.get(data, `consumo_per_vettore.${k}`)} {(vettori_energetici_lookup[k] as any).um}
						</td>
					</tr>
				))}
				<tr>
					<th>Spesa per vettore:</th>
				</tr>
				{_.keys(_.get(data, 'spesa_per_vettore', {})).map(k => (
					<tr key={`spesa_per_vettore.${k}`}>
						<TH>{k.replace(/_/g, ' ')}:</TH>
						<td>{_.get(data, `spesa_per_vettore.${k}`)} €</td>
					</tr>
				))}
				<tr>
					<th>Consumo en primaria per settore:</th>
				</tr>
				{['ENERGIA_TERMICA', 'ENERGIA_ELETTRICA', 'ILLUMINAZIONE_PUBBLICA', 'VEICOLI'].map(k => (
					<tr key={`consumo_en_primaria_per_settore.${k}`}>
						<TH>{k.replace(/_/g, ' ')}:</TH>
						<td>{_.get(data, `consumo_en_primaria_per_settore.${k}`, 0)} kWh</td>
					</tr>
				))}
				<tr>
					<th>Spesa per settore:</th>
				</tr>
				{['ENERGIA_TERMICA', 'ENERGIA_ELETTRICA', 'ILLUMINAZIONE_PUBBLICA', 'VEICOLI'].map(k => (
					<tr key={`spesa_per_settore.${k}`}>
						<TH>{k.replace(/_/g, ' ')}:</TH>
						<td>{_.get(data, `spesa_per_settore.${k}`, 0)} €</td>
					</tr>
				))}
				<tr>
					<th>Consumo energia elettrica tot edifici:</th>
				</tr>
				<tr>
					<TH>consumo:</TH>
					<td>{_.get(data, 'consumo_energia_elettrica_tot_edifici.consumo')} kWh</td>
				</tr>
				<tr>
					<TH>superficie:</TH>
					<td>{_.get(data, 'consumo_energia_elettrica_tot_edifici.mq')} mq</td>
				</tr>
				<tr>
					<TH>indicatore su mq:</TH>
					<td>{_.get(data, 'consumo_energia_elettrica_tot_edifici._indicatore_su_mq')} kWh/mq</td>
				</tr>
				<tr>
					<TH>indicatore su abitanti:</TH>
					<td>
						{_.get(data, 'consumo_energia_elettrica_tot_edifici._indicatore_su_abitanti')}{' '}
						kWh/abitante
					</td>
				</tr>

				<tr>
					<th>Consumo energia elettrica per categorie di edifici:</th>
				</tr>
				{_.keys(_.get(data, 'consumo_energia_elettrica_per_categorie_edifici', {})).map(k => (
					<>
						<tr>
							<TH>{k.replace(/_/g, ' ').replace('undefined', 'NESSUNA CATEGORIA')}:</TH>
						</tr>
						<tr>
							<TH indent={2}>consumo:</TH>
							<td>
								{_.get(data, `consumo_energia_elettrica_per_categorie_edifici.${k}.consumo`)} kWh
							</td>
						</tr>
						<tr>
							<TH indent={2}>superficie:</TH>
							<td>{_.get(data, `consumo_energia_elettrica_per_categorie_edifici.${k}.mq`)} mq</td>
						</tr>

						<tr>
							<TH indent={2}>indicatore su mq:</TH>
							<td>
								{_.get(
									data,
									`consumo_energia_elettrica_per_categorie_edifici.${k}._indicatore_su_mq`
								)}{' '}
								kWh/mq
							</td>
						</tr>
						<tr>
							<TH indent={2}>indicatore su abitanti:</TH>
							<td>
								{_.get(
									data,
									`consumo_energia_elettrica_per_categorie_edifici.${k}._indicatore_su_abitanti`
								)}{' '}
								kWh/abitante
							</td>
						</tr>
					</>
				))}

				<tr>
					<th>Consumo energia termica tot edifici:</th>
				</tr>
				<tr>
					<TH>consumo:</TH>
					<td>{_.get(data, `consumo_energia_termica_tot_edifici.consumo_kwh`)} kWh</td>
				</tr>
				<tr>
					<TH>consumo su gradi giorno:</TH>
					<td>{_.get(data, `consumo_energia_termica_tot_edifici.consumo_kwh_gg`)} kWh/gg</td>
				</tr>
				<tr>
					<TH>superficie:</TH>
					<td>{_.get(data, `consumo_energia_termica_tot_edifici.mq`)} mq</td>
				</tr>
				<tr>
					<TH>indicatore su mq:</TH>
					<td>{_.get(data, `consumo_energia_termica_tot_edifici._indicatore_su_mq`)} kWh/mq</td>
				</tr>
				<tr>
					<TH>indicatore su mq e gradi giorno:</TH>
					<td>
						{_.get(data, `consumo_energia_termica_tot_edifici._indicatore_su_mq_gg`)} kWh/mq/gg
					</td>
				</tr>
				<tr>
					<TH>indicatore su abitanti:</TH>
					<td>
						{_.get(data, `consumo_energia_termica_tot_edifici._indicatore_su_abitanti`)}{' '}
						kWh/abitante
					</td>
				</tr>
				<tr>
					<TH>indicatore su abitanti e gradi giorno:</TH>
					<td>
						{_.get(data, `consumo_energia_termica_tot_edifici._indicatore_su_abitanti_gg`)}{' '}
						kWh/abitante/gg
					</td>
				</tr>

				<tr>
					<th>Consumo energia termica per categorie di edifici:</th>
				</tr>
				{_.keys(_.get(data, 'consumo_energia_termica_per_categorie_edifici', {})).map(k => (
					<>
						<tr>
							<TH>{k.replace(/_/g, ' ').replace('undefined', 'NESSUNA CATEGORIA')}:</TH>
						</tr>
						<tr>
							<TH indent={2}>consumo:</TH>
							<td>
								{_.get(data, `consumo_energia_termica_per_categorie_edifici.${k}.consumo_kwh`)} kWh
							</td>
						</tr>
						<tr>
							<TH indent={2}>consumo su gradi giorno:</TH>
							<td>
								{_.get(data, `consumo_energia_termica_per_categorie_edifici.${k}.consumo_kwh_gg`)}{' '}
								kWh/gg
							</td>
						</tr>
						<tr>
							<TH indent={2}>superficie:</TH>
							<td>{_.get(data, `consumo_energia_termica_per_categorie_edifici.${k}.mq`)} mq</td>
						</tr>

						<tr>
							<TH indent={2}>indicatore su mq:</TH>
							<td>
								{_.get(
									data,
									`consumo_energia_termica_per_categorie_edifici.${k}._indicatore_su_mq`
								)}{' '}
								kWh/mq
							</td>
						</tr>
						<tr>
							<TH indent={2}>indicatore su mq e gradi giorno:</TH>
							<td>
								{_.get(
									data,
									`consumo_energia_termica_per_categorie_edifici.${k}._indicatore_su_mq_gg`
								)}{' '}
								kWh/mq/gg
							</td>
						</tr>
						<tr>
							<TH indent={2}>indicatore su abitanti:</TH>
							<td>
								{_.get(
									data,
									`consumo_energia_termica_per_categorie_edifici.${k}._indicatore_su_abitanti`
								)}{' '}
								kWh/abitante
							</td>
						</tr>
						<tr>
							<TH indent={2}>indicatore su abitanti e gradi giorno:</TH>
							<td>
								{_.get(
									data,
									`consumo_energia_termica_per_categorie_edifici.${k}._indicatore_su_abitanti_gg`
								)}{' '}
								kWh/abitante/gg
							</td>
						</tr>
					</>
				))}
				<tr>
					<th>Emissioni CO2 tot:</th>
					<td>{_.get(data, 'emissioni_co2_tot') + ' ton'}</td>
				</tr>

				<tr>
					<th>Emissioni CO2 per settore:</th>
				</tr>
				{['ENERGIA_TERMICA', 'ENERGIA_ELETTRICA', 'ILLUMINAZIONE_PUBBLICA', 'VEICOLI'].map(k => (
					<tr key={`emissioni_co2_per_settore.${k}`}>
						<TH>{k.replace(/_/g, ' ')}:</TH>
						<td>{_.get(data, `emissioni_co2_per_settore.${k}`, 0)} €</td>
					</tr>
				))}

				<tr>
					<th>Consumo carburante veicoli:</th>
				</tr>
				<tr>
					<TH>Consumo</TH>
					<td>{_.get(data, 'consumo_carburante_veicoli.kwh_tot') + ' kWh'}</td>
				</tr>
				<tr>
					<TH>Consumo per abitante</TH>
					<td>{_.get(data, 'consumo_carburante_veicoli.kwh_su_abitanti') + ' kWh/abitante'}</td>
				</tr>
				<tr>
					<TH>Consumo per 100 km</TH>
					<td>{(_.get(data, 'consumo_carburante_veicoli.kwh_su_100km') || '-') + ' kWh/100km'}</td>
				</tr>

				<tr>
					<th>Consumo illuminazione pubblica:</th>
				</tr>
				<tr>
					<TH>Consumo</TH>
					<td>{_.get(data, 'consumo_illuminazione_pubblica.kwh_tot') + ' kWh'}</td>
				</tr>
				<tr>
					<TH>Consumo per abitante</TH>
					<td>{_.get(data, 'consumo_illuminazione_pubblica.kwh_su_abitanti') + ' kWh/abitante'}</td>
				</tr>
				<tr>
					<TH>Consumo per punto luce</TH>
					<td>
						{(_.get(data, 'consumo_illuminazione_pubblica.kwh_su_pl') || '-') + ' kWh/punto luce'}
					</td>
				</tr>

				<tr>
					<th>Produzione en elettrica tot:</th>
					<td>{_.get(data, 'produzione_en_elettrica_tot') + ' kWh'}</td>
				</tr>
				<tr>
					<th>Produzione en termica tot:</th>
					<td>{_.get(data, 'produzione_en_termica_tot') + ' kWh'}</td>
				</tr>
			</table>
		</Container>
	)
}

export default TextualReportRenderer
