import React from 'react'
import ReactDOMServer from 'react-dom/server'
import _ from 'lodash'
import {saveAs} from 'file-saver'
import htmlDocx from 'html-docx-js/dist/html-docx'

import {BarChart} from './charts/bar-chart'
import {Button} from 'antd'
import {VGroup, Container} from './ui'
import {svg2png} from '../utils'

const colors = {
	BLUE: '#4472C4',
	ORANGE: '#ED7D31',
	GRAY: '#A5A5A5',
	YELLOW: '#FFC001',
}

function estraiMetrica({risultati, metrica}: {risultati: any; metrica: string}) {
	return _.keys(risultati).reduce(
		(acc, yr) => ({...acc, [yr]: _.get(risultati, `${yr}.${metrica}`)}),
		{}
	)
}

const ReportChartRenderer = ({data}) => {
	const {gradi_giorno_tipo, risultati, benchmarks} = data

	const vettori_series = [
		{key: 'METANO', color: '#4472C4'},
		{key: 'ENERGIA_ELETTRICA', color: '#ED7D31'},
	]
	const categorie_edifici = [
		{key: 'SCUOLE_E_ASILI', label: 'Scuole e asili', color: colors.BLUE},
		{
			key: 'UFFICI_E_ASSIMILABILI',
			label: 'Uffici e assimilabili',
			color: colors.ORANGE,
		},
		{key: 'EDIFICI_SPORTIVI', label: 'Edifici sportivi', color: colors.GRAY},
		{key: 'ALTRO', label: 'Altro', color: colors.YELLOW},
	]
	const settori_series = [
		{key: 'ILLUMINAZIONE_PUBBLICA', color: colors.BLUE},
		{key: 'ENERGIA_ELETTRICA', color: colors.ORANGE},
		{key: 'ENERGIA_TERMICA', color: colors.GRAY},
		{key: 'VEICOLI', color: colors.YELLOW},
	]

	const chartConfigs = [
		{
			title: '1.a - Consumo energia primaria annuale totale',
			data: risultati,
			series: [{key: 'consumo_en_primaria_tot', color: 'tomato'}],
		},
		{
			title: '1.b - Consumo energia primaria annuale per settore',
			data: estraiMetrica({
				risultati,
				metrica: 'consumo_en_primaria_per_settore',
			}),
			series: settori_series,
		},
		{
			title: '2.a - Spese annuali totali',
			data: risultati,
			series: [
				{
					key: 'spesa_tot',
					color: '#ED7D31',
				},
			],
		},
		{
			title: '2.b - Spese annuali per settore',
			data: estraiMetrica({
				risultati,
				metrica: 'spesa_per_settore',
			}),
			series: settori_series,
		},
		{
			title: '3.a - Consumo annuale per vettore energetico',
			data: estraiMetrica({
				risultati,
				metrica: 'consumo_per_vettore',
			}),
			series: vettori_series,
		},
		{
			title: '3.b - Spese annuali per vettore energetico',
			data: estraiMetrica({
				risultati,
				metrica: 'spesa_per_vettore',
			}),
			series: vettori_series,
		},
		{
			title: '4.a - Consumo energia elettrica per ill. pubb.',
			data: risultati,
			series: [
				{
					key: 'consumo_illuminazione_pubblica.kwh_tot',
					color: colors.BLUE,
				},
			],
		},
		{
			title: '4.b - Consumo energia elettrica ill. pubb. per abitante',
			data: risultati,
			series: [
				{
					key: 'consumo_illuminazione_pubblica.kwh_su_abitanti',
					color: colors.BLUE,
				},
			],
			benchmarks: _.get(benchmarks, 'consumo_illuminazione_pubblica.kwh_su_abitanti', []).map(
				value => ({
					value,
				})
			),
		},
		{
			title: '4.c - Consumo energia elettrica ill. pubb. per punto luce',
			data: risultati,
			series: [
				{
					key: 'consumo_illuminazione_pubblica.kwh_su_pl',
					color: colors.BLUE,
				},
			],
			benchmarks: _.get(benchmarks, 'consumo_illuminazione_pubblica.kwh_su_pl', []).map(value => ({
				value,
			})),
		},
		{
			title: '5.a - Consumo totale energia elettrica per edifici',
			data: risultati,
			series: [
				{
					key: 'consumo_energia_elettrica_tot_edifici.consumo',
					color: colors.ORANGE,
				},
			],
		},
		{
			title: '5.b - Consumo totale energia elettrica per edifici per abitante',
			data: risultati,
			series: [
				{
					key: 'consumo_energia_elettrica_tot_edifici._indicatore_su_abitanti',
					color: colors.ORANGE,
				},
			],
			benchmarks: _.get(
				benchmarks,
				'consumo_energia_elettrica_tot_edifici._indicatore_su_abitanti',
				[]
			).map(value => ({
				value,
			})),
		},
		{
			title: '5.c - Consumo totale energia elettrica per edifici per mq',
			data: risultati,
			series: [
				{
					key: 'consumo_energia_elettrica_tot_edifici._indicatore_su_mq',
					color: colors.ORANGE,
				},
			],
			benchmarks: _.get(
				benchmarks,
				'consumo_energia_elettrica_tot_edifici._indicatore_su_mq',
				[]
			).map(value => ({
				value,
			})),
		},
		{
			title: '5.d - Consumo energia elettrica per edifici per categoria',
			data: estraiMetrica({
				risultati,
				metrica: 'consumo_energia_elettrica_per_categorie_edifici',
			}),
			series: [
				// { key: "undefined.consumo", color: "tomato" },
				...categorie_edifici.map(c => ({...c, key: c.key + '.consumo'})),
			],
		},
		...categorie_edifici.map((c, i) => ({
			key: `5.e.${i + 1}`,
			title: `5.e.${i + 1} - Consumo energia elettrica per edifici per categoria per mq (${
				c.label
			})`,
			data: estraiMetrica({
				risultati,
				metrica: `consumo_energia_elettrica_per_categorie_edifici.${c.key}`,
			}),
			series: [
				{
					key: '_indicatore_su_mq',
					color: c.color,
				},
			],
			benchmarks: _.get(
				benchmarks,
				`consumo_energia_elettrica_per_categorie_edifici.${c.key}._indicatore_su_mq`,
				[]
			).map(value => ({
				value,
			})),
		})),
		{
			title: '6.a - Consumo totale energia termica per edifici',
			data: risultati,
			series: [
				{
					key: 'consumo_energia_termica_tot_edifici.consumo_kwh',
					color: colors.ORANGE,
				},
			],
		},
		{
			title: (
				<span>
					6.b - Consumo totale energia termica per edifici normalizzato sui GG
					<sub>a</sub>
				</span>
			),
			data: _.toPairs(
				estraiMetrica({
					risultati,
					metrica: `consumo_energia_termica_tot_edifici.consumo_kwh_gg`,
				})
			).reduce(
				(acc, [anno, val]) => ({
					...acc,
					[anno]: {
						value: val && (val as number) * (risultati[anno]._gradi_giorno as number),
					},
				}),
				{}
			),
			series: [
				{
					key: 'value',
					color: colors.ORANGE,
				},
			],
		},
		{
			title: (
				<span>
					6.c - Consumo totale energia termica per edifici per abitante normalizzato sui GG
					<sub>a</sub>
				</span>
			),
			data: _.toPairs(
				estraiMetrica({
					risultati,
					metrica: `consumo_energia_termica_tot_edifici._indicatore_su_abitanti_gg`,
				})
			).reduce(
				(acc, [anno, val]) => ({
					...acc,
					[anno]: {
						value: val && (val as number) * (gradi_giorno_tipo as number),
					},
				}),
				{}
			),
			series: [
				{
					key: 'value',
					color: colors.ORANGE,
				},
			],
			benchmarks: _.get(
				benchmarks,
				'consumo_energia_termica_tot_edifici._indicatore_su_abitanti_gg',
				[]
			).map(value => ({value: value * gradi_giorno_tipo})),
		},
		{
			title: (
				<span>
					6.d - Consumo totale energia termica per edifici per mq normalizzato sui GG
					<sub>a</sub>
				</span>
			),
			data: _.toPairs(
				estraiMetrica({
					risultati,
					metrica: `consumo_energia_termica_tot_edifici._indicatore_su_mq_gg`,
				})
			).reduce(
				(acc, [anno, val]) => ({
					...acc,
					[anno]: {
						value: val && (val as number) * (gradi_giorno_tipo as number),
					},
				}),
				{}
			),
			series: [
				{
					key: 'value',
					color: colors.ORANGE,
				},
			],
			benchmarks: _.get(
				benchmarks,
				'consumo_energia_termica_tot_edifici._indicatore_su_mq_gg',
				[]
			).map(value => ({value: value * gradi_giorno_tipo})),
		},
		{
			title: '6.e - Consumo energia termica per edifici per categoria',
			data: estraiMetrica({
				risultati,
				metrica: 'consumo_energia_termica_per_categorie_edifici',
			}),
			series: [
				// { key: "undefined.consumo", color: "tomato" },
				{key: 'SCUOLE_E_ASILI.consumo_kwh', color: colors.BLUE},
				{
					key: 'UFFICI_E_ASSIMILABILI.consumo_kwh',
					color: colors.ORANGE,
				},
				{key: 'EDIFICI_SPORTIVI.consumo_kwh', color: colors.GRAY},
				{key: 'ALTRO.consumo_kwh', color: colors.YELLOW},
			],
		},
		{
			title: (
				<span>
					6.f - Consumo energia termica per edifici per categoria normalizzato sui GG
					<sub>a</sub>
				</span>
			),
			data: _.toPairs(
				estraiMetrica({
					risultati,
					metrica: `consumo_energia_termica_per_categorie_edifici`,
				})
			).reduce((acc, [anno, dati]) => {
				acc[anno] = _.keys(dati).reduce(
					(acc, k) => ({...acc, [k]: {value: dati[k].consumo_kwh_gg * gradi_giorno_tipo}}),
					{}
				)
				return acc
			}, {}),
			series: [
				// { key: "undefined.consumo", color: "tomato" },
				{key: 'SCUOLE_E_ASILI.value', color: colors.BLUE},
				{
					key: 'UFFICI_E_ASSIMILABILI.value',
					color: colors.ORANGE,
				},
				{key: 'EDIFICI_SPORTIVI.value', color: colors.GRAY},
				{key: 'ALTRO.value', color: colors.YELLOW},
			],
		},
		...categorie_edifici.map((c, i) => ({
			key: `6.g.${i + 1}`,
			title: (
				<span>
					6.g.{i + 1} - Consumo energia termica per edifici per mq per castegoria normalizzato sui
					GG
					<sub>a</sub> ({c.label})
				</span>
			),
			data: _.toPairs(
				estraiMetrica({
					risultati,
					metrica: `consumo_energia_termica_per_categorie_edifici.${c.key}._indicatore_su_mq_gg`,
				})
			).reduce(
				(acc, [yr, value]) => ({
					...acc,
					[yr]: {value: (value as number) * gradi_giorno_tipo},
				}),
				{}
			),
			series: [
				// { key: "undefined.consumo", color: "tomato" },
				{key: 'value', color: c.color},
			],
			benchmarks: _.get(
				benchmarks,
				`consumo_energia_termica_per_categorie_edifici.${c.key}._indicatore_su_mq_gg`,
				[]
			).map(value => ({value: value * gradi_giorno_tipo})),
		})),
		{
			title: '7.a - Consumo carburante per veicoli',
			data: risultati,
			series: [
				{
					key: 'consumo_carburante_veicoli.kwh_tot',
					color: colors.YELLOW,
				},
			],
		},
		{
			title: '7.b - Consumo carburante per veicoli per abitante',
			data: risultati,
			series: [
				{
					key: 'consumo_carburante_veicoli.kwh_su_abitanti',
					color: colors.YELLOW,
				},
			],
			benchmarks: _.get(benchmarks, 'consumo_carburante_veicoli.kwh_su_abitanti', []).map(
				value => ({
					value,
				})
			),
		},
		{
			title: '7.c - Consumo carburante per veicoli per 100 km',
			data: risultati,
			series: [
				{
					key: 'consumo_carburante_veicoli.kwh_su_100km',
					color: colors.YELLOW,
				},
			],
			benchmarks: _.get(benchmarks, 'consumo_carburante_veicoli.kwh_su_100km', []).map(value => ({
				value,
			})),
		},
		{
			title: '8.a - Produzione annua di energia elettrica da FER',
			data: risultati,
			series: [
				{
					key: 'produzione_en_elettrica_tot',
					color: colors.ORANGE,
				},
			],
		},
		{
			title: '8.b - Produzione annua di termica elettrica da FER',
			data: risultati,
			series: [
				{
					key: 'produzione_en_termica_tot',
					color: colors.GRAY,
				},
			],
		},
		{
			title: (
				<span>
					9.a - Emissioni totali annue di CO<sub>2</sub>
				</span>
			),
			data: risultati,
			series: [
				{
					key: 'emissioni_co2_tot',
					color: colors.GRAY,
				},
			],
		},
		{
			title: (
				<span>
					9.b - Emissioni annue di CO<sub>2</sub> per settore
				</span>
			),
			data: estraiMetrica({
				risultati,
				metrica: 'emissioni_co2_per_settore',
			}),
			series: settori_series,
		},
	]

	const refs = []

	return (
		<Container>
			<VGroup>
				<Button
					onClick={async _e => {
						const renderedCharts = await Promise.all(
							refs.map(async ({svg, ...meta}) => {
								const w = +svg.attributes.width.value // * 2
								const h = +svg.attributes.height.value // * 2
								const png = await svg2png(svg, w * 2, h * 2)
								return {
									...meta,
									svg,
									png,
									w,
									h,
								}
							})
						)

						var content = `<!DOCTYPE html>
							<html><body>
							${ReactDOMServer.renderToStaticMarkup(
								<div>
									{renderedCharts.map(({png, w, h, title}) => {
										return (
											<div>
												<h2>{title}</h2>
												<img
													src={png.replace('data:image/octet-stream', 'data:image/png')}
													width={w}
													height={h}
												/>
											</div>
										)
									})}
								</div>
							)}</body></html>`

						var converted = htmlDocx.asBlob(content)
						saveAs(converted, 'report.docx')
					}}
				>
					Esporta
				</Button>
				<div>
					{chartConfigs.map((config, i) => (
						<BarChart
							key={i}
							{...config}
							ref={el => {
								if (el) {
									refs[i] = {
										i,
										...config,
										svg: el.getSVG(),
									}
								}
							}}
						/>
					))}
				</div>
			</VGroup>
		</Container>
	)
}

export default ReportChartRenderer
