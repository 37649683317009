import {useState, useEffect, useReducer, useMemo} from 'react'
import axios from 'axios'

export const comuni = [
	`Aiello del Friuli`,
	`Amaro`,
	`Ampezzo`,
	`Andreis`,
	`Aquileia`,
	`Arba`,
	`Arta Terme`,
	`Artegna`,
	`Arzene`,
	`Attimis`,
	`Aviano`,
	`Azzano Decimo`,
	`Bagnaria Arsa`,
	`Barcis`,
	`Basiliano`,
	`Bertiolo`,
	`Bicinicco`,
	`Bordano`,
	`Brugnera`,
	`Budoia`,
	`Buja`,
	`Buttrio`,
	`Camino al Tagliamento`,
	`Campoformido`,
	`Campolongo Tapogliano`,
	`Caneva`,
	`Capriva del Friuli`,
	`Carlino`,
	`Casarsa della Delizia`,
	`Cassacco`,
	`Castelnovo del Friuli`,
	`Castions di Strada`,
	`Cavasso Nuovo`,
	`Cavazzo Carnico`,
	`Cercivento`,
	`Cervignano del Friuli`,
	`Chions`,
	`Chiopris-Viscone`,
	`Chiusaforte`,
	`Cimolais`,
	`Cividale del Friuli`,
	`Claut`,
	`Clauzetto`,
	`Codroipo`,
	`Codroipo`,
	`Colloredo di Monte Albano`,
	`Comeglians`,
	`Cordenons`,
	`Cordovado`,
	`Cormons`,
	`Corno di Rosazzo`,
	`Coseano`,
	`Dignano`,
	`Doberdò del Lago`,
	`Dogna`,
	`Dolegna del Collio`,
	`Drenchia`,
	`Duino-Aurisina`,
	`Enemonzo`,
	`Erto e Casso`,
	`Faedis`,
	`Fagagna`,
	`Fanna`,
	`Farra d'Isonzo`,
	`Fiume Veneto`,
	`Fiumicello`,
	`Flaibano`,
	`Fogliano Redipuglia`,
	`Fontanafredda`,
	`Forgaria nel Friuli`,
	`Forni Avoltri`,
	`Forni di Sopra`,
	`Forni di Sotto`,
	`Frisanco`,
	`Gemona del Friuli`,
	`Gonars`,
	`Gorizia`,
	`Gradisca d'Isonzo`,
	`Grado`,
	`Grimacco`,
	`Latisana`,
	`Lauco`,
	`Lestizza`,
	`Lignano Sabbiadoro`,
	`Ligosullo`,
	`Lusevera`,
	`Magnano in Riviera`,
	`Majano`,
	`Malborghetto Valbruna`,
	`Maniago`,
	`Manzano`,
	`Marano Lagunare`,
	`Mariano del Friuli`,
	`Martignacco`,
	`Medea`,
	`Meduno`,
	`Mereto di Tomba`,
	`Moggio Udinese`,
	`Mogliano Veneto`,
	`Moimacco`,
	`Monfalcone`,
	`Monrupino`,
	`Montenars`,
	`Montereale Valcellina`,
	`Moraro`,
	`Morsano al Tagliamento`,
	`Mortegliano`,
	`Moruzzo`,
	`Mossa`,
	`Muggia`,
	`Muzzana del Turgnano`,
	`Nimis`,
	`Osoppo`,
	`Ovaro`,
	`Pagnacco`,
	`Palazzolo dello Stella`,
	`Palmanova`,
	`Paluzza`,
	`Pasian di Prato`,
	`Pasiano di Pordenone`,
	`Paularo`,
	`Pavia di Udine`,
	`Pinzano al Tagliamento`,
	`Pocenia`,
	`Polcenigo`,
	`Pontebba`,
	`Ponzano Veneto`,
	`Porcia`,
	`Pordenone`,
	`Porpetto`,
	`Povoletto`,
	`Pozzuolo del Friuli`,
	`Pradamano`,
	`Prata di Pordenone`,
	`Prato Carnico`,
	`Pravisdomini`,
	`Precenicco`,
	`Preganziol`,
	`Premariacco`,
	`Preone`,
	`Prepotto`,
	`Prov UD`,
	`Pulfero`,
	`Quinto di Treviso`,
	`Ragogna`,
	`Ravascletto`,
	`Raveo`,
	`Reana del Rojale`,
	`Remanzacco`,
	`Resia`,
	`Resiutta`,
	`Rigolato`,
	`Rive d'Arcano`,
	`Rivignano`,
	`Romans d'Isonzo`,
	`Ronchi dei Legionari`,
	`Ronchis`,
	`Roveredo in Piano`,
	`Ruda`,
	`Sacile`,
	`Sagrado`,
	`San Canzian d'Isonzo`,
	`San Daniele del Friuli`,
	`San Dorligo della Valle - Dolina`,
	`San Floriano del Collio`,
	`San Giorgio della Richinvelda`,
	`San Giorgio di Nogaro`,
	`San Giovanni al Natisone`,
	`San Leonardo`,
	`San Lorenzo Isontino`,
	`San Martino al Tagliamento`,
	`San Pier d'Isonzo`,
	`San Pietro al Natisone`,
	`San Quirino`,
	`San Vito al Tagliamento`,
	`San Vito al Torre`,
	`San Vito di Fagagna`,
	`Santa Maria la Longa`,
	`Sauris`,
	`Savogna`,
	`Savogna d'Isonzo`,
	`Sedegliano`,
	`Sempeter-Vrtojba`,
	`Sequals`,
	`Sesto al Reghena`,
	`Sgonico`,
	`Silea`,
	`Socchieve`,
	`Spilimbergo`,
	`Staranzano`,
	`Stregna`,
	`Sutrio`,
	`Taipana`,
	`Talmassons`,
	`Tarcento`,
	`Tarvisio`,
	`Tavagnacco`,
	`Teor`,
	`Terzo d'Aquileia`,
	`Tolmezzo`,
	`Tolmin`,
	`Torreano`,
	`Torviscosa`,
	`Tramonti di Sopra`,
	`Tramonti di Sotto`,
	`Trasaghis`,
	`Travesio`,
	`Treppo Carnico`,
	`Treppo Grande`,
	`Tricesimo`,
	`Trieste`,
	`Trivignano Udinese`,
	`Turriaco`,
	`Udine`,
	`Vajont`,
	`Valvasone`,
	`Valvasone Arzene`,
	`Varmo`,
	`Venzone`,
	`Verzegnis`,
	`Villa Santina`,
	`Villa Vicentina`,
	`Villesse`,
	`Villorba`,
	`Visco`,
	`Vito d'Asio`,
	`Vivaro`,
	`Zoppola`,
	`Zuglio`,
]
export const anni = [2014, 2015, 2016, 2017, 2018]

export const useDatiComuneAnno = ({codistat, anno}) => {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({} as any)
	useEffect(() => {
		setLoading(true)
		setError(null)
		axios
			.get(`/api/comune/${codistat}/${anno}`)
			.then(({data}) => setData(data))
			.catch(err => setError(err))
			.then(() => setLoading(false))
	}, [codistat, anno])
	return {data, loading, error}
}

export function useOverviewData() {
	const [state, setState] = useReducer((oldState, newState) => ({...oldState, ...newState}), {
		loading: true,
		data: null,
		error: null,
	})
	useEffect(() => {
		axios
			.get('/api/overview')
			.then(({data}) => setState({data, loading: false}))
			.catch(err => setState({error: err.message, loading: false}))
	}, [])
	return state
}

export const extractDataForTextualReport = ({data, anno}) =>
	data && data.risultati
		? {...data.risultati[anno], _vettori_energetici: data._vettori_energetici}
		: {}

export const useReportData = ({anno, codistat}): {loading: boolean; data: any; error: string} => {
	const [state, setState] = useReducer((oldState, newState) => ({...oldState, ...newState}), {
		loading: true,
		data: null,
		error: null,
	})
	useEffect(() => {
		axios
			.get(`/api/report/${codistat}/${anno}`)
			.then(({data}) => setState({data, loading: false}))
			.catch(err => setState({error: err.message, loading: false}))
	}, [anno, codistat])
	return state
}

export const useData = ({codistat, anno}) => {
	const {data, loading, error} = useReportData({codistat, anno})
	return {data: extractDataForTextualReport({data, anno}), loading, error}
}

export const useComuni = () => {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({} as any)
	useEffect(() => {
		setLoading(true)
		setError(null)
		axios
			.get(`/api/comuni`)
			.then(({data}) => setData(data))
			.catch(err => setError(err))
			.then(() => setLoading(false))
	}, [])
	return {data, loading, error}
}
