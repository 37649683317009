import React, {useState, useMemo} from 'react'
import {Button, message, Input, Spin, Icon} from 'antd'
import _ from 'lodash'
import {withRouter} from 'react-router-dom'
import {Form as FinalForm, Field} from 'react-final-form'
import axios from 'axios'
import Box from 'ui-box'

import {useDatiComuneAnno} from '../data'
import {MyFormItem} from '../components/forms'
import {Container} from '../components/ui'

const InspectionButtonsContainer = ({children}) => (
	<div
		style={{
			width: 160,
			display: 'flex',
			justifyContent: 'space-around',
			padding: '0 10px',
		}}
	>
		{children}
	</div>
)

const inspectionButtonProps = {
	target: '_blank',
	style: {
		lineHeight: '30px',
	},
}

const ControlPage = withRouter(({match, history}) => {
	const {codistat, anno} = match.params
	const {data, loading, error} = useDatiComuneAnno({codistat, anno})
	const metriche_anno = useMemo(
		() => _.omit(_.get(data, `metriche_comune.${anno}`), ['codistat', 'anno']),
		[data]
	)

	const [saving, setSaving] = useState(false)
	return (
		<Container>
			<Spin spinning={loading}>
				<FinalForm
					onSubmit={values => {
						console.log({anno, codistat, ...values})
						setSaving(true)
						axios
							.post(`/api/comune/${codistat}/${anno}`, values)
							.then(({data: {success, error}}) => {
								success
									? message.success(
											`I benchmark del comune di ${codistat} per l'anno ${anno} sono stati calcolati e salvati`
									  )
									: message.error(`Si è verificato un errore nel salvataggio dei dati`)
								setSaving(false)
							})
					}}
					validate={values => {
						var errors: any = {}
						if (!values.n_abitanti) errors.n_abitanti = 'Questo valore è obbligatorio'
						if (!values.n_punti_luce) errors.n_punti_luce = 'Questo valore è obbligatorio'
						if (!values.gradi_giorno) errors.gradi_giorno = 'Questo valore è obbligatorio'
						return errors
					}}
					initialValues={metriche_anno}
				>
					{({handleSubmit}) => {
						return (
							<form onSubmit={handleSubmit}>
								<Field
									name="n_abitanti"
									render={({input, meta: {error, touched}}) => (
										<MyFormItem
											label="N° abitanti"
											error={touched && error ? error : null}
											required
										>
											<Input {...input} />
										</MyFormItem>
									)}
								/>
								<Field
									name="n_punti_luce"
									render={({input, meta: {error, touched}}) => (
										<MyFormItem
											label="N° punti luce"
											error={touched && error ? error : null}
											help={`${_.get(
												data,
												'altro.n_punti_luce_attuali',
												'?'
											)} punti luce attualmente in catasto`}
											required
										>
											<Input {...input} />
										</MyFormItem>
									)}
								/>
								<Field
									name="gradi_giorno"
									render={({input, meta: {error, touched}}) => (
										<MyFormItem
											label="Gradi-giorno reali"
											error={touched && error ? error : null}
											required
										>
											<Input {...input} />
										</MyFormItem>
									)}
								/>

								<MyFormItem label="Gradi-giorno tipo">
									<Input disabled value={_.get(data, 'comune.gradi_giorno_tipo', '?')} />
								</MyFormItem>
								<MyFormItem
									label="N° centraline con copertura completa"
									help={`su ${_.get(data, 'altro.n_centraline_attuali', '?')} centraline totali`}
								>
									<Box display="flex">
										<Input disabled value={_.get(data, 'altro.n_centraline_calcolate', '?')} />
										<InspectionButtonsContainer>
											<a
												href={`/api/comune/${codistat}/${anno}/centraline-incluse`}
												{...inspectionButtonProps}
											>
												Incluse
											</a>
											<a
												href={`/api/comune/${codistat}/${anno}/centraline-escluse`}
												{...inspectionButtonProps}
											>
												Escluse
											</a>
										</InspectionButtonsContainer>
									</Box>
								</MyFormItem>
								<MyFormItem
									label="N° veicoli considerati"
									help={`su ${_.get(data, 'altro.n_veicoli_attuali', '?')} veicoli totali`}
								>
									<Box display="flex">
										<Input disabled value={_.get(data, 'altro.n_veicoli_calcolati', '?')} />
										<InspectionButtonsContainer>
											<a
												href={`/api/comune/${codistat}/${anno}/veicoli-inclusi`}
												{...inspectionButtonProps}
											>
												Inclusi
											</a>
											<a
												href={`/api/comune/${codistat}/${anno}/veicoli-esclusi`}
												{...inspectionButtonProps}
											>
												Esclusi
											</a>
										</InspectionButtonsContainer>
									</Box>
								</MyFormItem>
								<MyFormItem label="N° edifici (energia elettrica) considerati">
									<Box display="flex">
										<Input
											disabled
											value={_.get(data, 'altro.n_edifici_elettrici_calcolati', '?')}
										/>
										<InspectionButtonsContainer>
											<a
												href={`/api/comune/${codistat}/${anno}/edifici-elettrici-inclusi`}
												{...inspectionButtonProps}
											>
												Inclusi
											</a>
											<a
												href={`/api/comune/${codistat}/${anno}/edifici-elettrici-esclusi`}
												{...inspectionButtonProps}
											>
												Esclusi
											</a>
										</InspectionButtonsContainer>
									</Box>
								</MyFormItem>
								<MyFormItem
									label="N° edifici (energia termica) considerati"
									help={`su ${_.get(data, 'altro.n_edifici_attuali', '?')} edifici totali`}
								>
									<Box display="flex">
										<Input disabled value={_.get(data, 'altro.n_edifici_termici_calcolati', '?')} />
										<InspectionButtonsContainer>
											<a
												href={`/api/comune/${codistat}/${anno}/edifici-termici-inclusi`}
												{...inspectionButtonProps}
											>
												Inclusi
											</a>
											<a
												href={`/api/comune/${codistat}/${anno}/edifici-termici-esclusi`}
												{...inspectionButtonProps}
											>
												Esclusi
											</a>
										</InspectionButtonsContainer>
									</Box>
								</MyFormItem>

								<MyFormItem>
									<Button
										loading={saving}
										style={{marginRight: 16}}
										type="primary"
										htmlType="submit"
									>
										Salva e calcola
									</Button>
									<Button
										disabled={!metriche_anno}
										onClick={() => history.push(`/report/${codistat}/${anno}`)}
									>
										Vai al report
									</Button>
								</MyFormItem>
							</form>
						)
					}}
				</FinalForm>
			</Spin>
		</Container>
	)
})

export default ControlPage
